.form-list-item-wrapper {
  .ant-form-item-control-input-content {
    display: flex;
    .ant-form-item {
      flex: 1;
    }
    .dynamic-delete-button {
      font-size: 20px;
      margin-top: 6px;
      margin-left: 8px;
    }
    .ant-form-item-extra {
      //color: $info-color-hover;
    }
  }
}
.range-title {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.range-title li {
  // margin-left: 20px;
  margin-top: -90px;
  font-size: smaller;
}
#range-title-poor {
  padding-left: 38px;
  color: #ff0000;
  z-index: 3;
}
#range-title-ba {
  padding-left: 46px;
  color: #ff0000;
  z-index: 3;
}
#range-title-good {
  padding-right: 30px;
  color: #a7e13e;
  z-index: 3;
}
#range-title-av {
  padding-right: 20px;
  color: #ffa500;
  z-index: 3;
}
#range-title-excellent {
  color: #a7e13e;
  z-index: 3;
  margin-right: -173px;
  // padding-right: 10px;
}

.slider-example {
  .ant-slider-track {
    background-color: #ff0000 !important;
    width: 55% !important;
  }
  .ant-slider-handle {
    display: none !important;
  }
}
.line {
  height: 4px;
  background-color: #a7e13e;
  width: 71px;
  margin-top: 22px;

  margin-right: 3px;
}
#line-1 {
  margin-right: -18px;
  z-index: 2;
}
#line-2 {
  z-index: 2;
  margin-right: 8px;
}
